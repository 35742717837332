
<template>
  <div class="conflex" style="justify-content:center;">
    <div class="agenda" style="justify-content:center;width:1220px;">
      <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno.header.header"
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div>

      <div class="conflex" style="padding-top:10px">
        <!-- Columna Apds/Municipios -->
        <div class="columna" style="width:25%">
          <!-- Select Jefes de Zona -->
          <div class="columna">
            <v-select
              v-bind="$select"
              v-model="schema.ctrls.zona.value"
              style="width:100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              :items="itemsZonas"
              item-value="id"
              item-text="name"
              :disabled="zona >0"
              @change="changeJZ">
            </v-select>
          </div>
          
          <!-- Tabs -->
          <div class="columna" style="padding-top:5px">
            <div class="conflex">
              <div class="cab" style="width:100px;cursor:pointer" @click="changeTab(1)">Apds</div>
              <div class="cab" style="width:100px;margin-left:5px;cursor:pointer" @click="changeTab(2)">Municipios</div>
            </div>
          </div>

          <!-- List -->
          <div class="columna">
            <!-- Apds -->
            <v-list v-if="tab==1">
              <v-subheader>Nombre APD / Código</v-subheader>
              <v-list-item-group
                v-model="selectedApd"
                color="primary"
                dense>
                  <v-list-item
                    v-for="(item, i) in itemsApds" :key="i"                  
                    dense
                    @click="change_Apd(item)">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.name"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.codigo"></v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
            </v-list>

            <!-- Municipios -->
            <v-list v-if="tab==2">
              <v-subheader>Municipio / Código</v-subheader>
              <v-list-item-group
                v-model="selectedMunicipio"
                color="primary">
                  <v-list-item
                    v-for="(item, i) in itemsMunicipio" :key="i"                  
                    dense
                    @click="getCuadrante(item)">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.pobla"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.codigo"></v-list-item-subtitle>
                      </v-list-item-content>
                  </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </div>

        <!-- Columna Calendario -->
        <div class="columna" style="width:48%;padding-left:10px;padding-top:5px">
            <div class="conflex" style="padding-top:5px;">            
              <v-btn
                v-bind="$cfg.btra_cfg.standard"  
                style="margin-right:10px"
                title="Busqueda Apd"
                @click="buscarApd()">
                  <v-icon dark>{{ "mdi-account-search-outline" }}</v-icon>
              </v-btn>

              <v-select
                v-bind="$select"
                v-model="schema.ctrls.year.value"
                :label="schema.ctrls.year.label"
                style="flex: 0 0 10%"
                :items="itemsYear"
                item-value="label"
                item-text="label"
                @change="changeYear">
              </v-select>

              <v-select
                v-bind="$select"
                v-model="schema.ctrls.mounth.value"
                :label="schema.ctrls.mounth.label"
                style="flex: 0 0 20%"
                :items="mes"
                item-value="id"
                item-text="label">
              </v-select>

              <v-select
                v-bind="$select"
                v-model="schema.ctrls.age.value"
                :label="schema.ctrls.age.label"
                style="flex: 0 0 38%"
                :items="itemsAgencia"
                item-value="id"
                item-text="name"
                @change="changeAgencia">
              </v-select>

              <v-select
                v-bind="$select"
                v-model="schema.ctrls.cia.value"
                :label="schema.ctrls.cia.label"
                style="flex: 0 0 13%"
                :items="itemsCia"
                item-value="id"
                item-text="label"
                @change="changeCia">
              </v-select>         
          </div>

          <div class="conflex">
            <div class="cab">Cuadrante</div>
            <div style="padding-left:10px">
              <span><v-icon :style="`color:${c[0].background}`" x-small>{{ 'mdi-circle' }}</v-icon> Libre</span>
              <span><v-icon :style="`color:${c[1].background}`" x-small>{{ 'mdi-circle' }}</v-icon> Guardia</span>
              <span><v-icon :style="`color:${c[5].background}`" x-small>{{ 'mdi-circle' }}</v-icon> Apoyo</span>
              <span><v-icon :style="`color:${c[9].background}`" x-small>{{ 'mdi-circle' }}</v-icon> No Disponible</span>
            </div>
          </div>
            
          <div class="conflex"> 
            <v-sheet v-bind="$cfg.styles.marco">
              
                <div style="display: flex">
                  <div class="mes"></div>
                  <div v-for="i in 24" :key="i">
                    <div class="hora">{{ i - 1 }}</div>
                  </div>
                </div>
            
              <!-- recorrer -->
              <div v-for="(m, indexm) in a" :key="indexm">
                <div v-for="(d, indexd) in m" :key="indexd">
                  <div v-if="schema.ctrls.mounth.value=='0' || indexm==schema.ctrls.mounth.value" style="display: flex">
                    <div class="mes">{{ mes[indexm].label }}-{{ indexd }}</div>
                    <div v-for="(h, indexh) in horas(d)" :key="indexh">
                      <div class="casilla" :style="tab==1? c[h] : (h==0? c[9] : c[1])" :title="get_title(indexm, indexd, indexh)">
                        {{ tab==1? '' : h }}
                      </div>
                      <!-- <div class="libre">{{m}}-{{d}}-{{h}} </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
        </div>

        <!-- Columna Asignación -->
        <div v-show="tab==1"  class="columna" style="width:27%">
          <div class="cab">ASIGNACION</div>
          <v-sheet v-bind="$cfg.styles.marco">
            <!-- // aqui viene la pantalla de asignar
            // no hay que sacar ventana
            // cuando se achiquen las casillas al tamaño suyo hay espacio a la derecha
            // Ahora estan enormes para ver  si  la información era correcta
            ASIGNACION
            COMBO
            COMBO
            COMBO
            PERIODO
            RADIOS Y CHECKS -->
            <div v-show="tab==1" class="conflex" style="justify-content:space-between;width:120px">
              <v-btn
                v-bind="$cfg.btra_cfg.standard"            
                title="Añadir Apd"
                @click="addApd()">
                  <v-icon dark>{{ "mdi-plus" }}</v-icon>
              </v-btn>

              <v-btn
                v-bind="$cfg.btra_cfg.standard"            
                title="Guardar Asignación"
                @click="saveAsignacion()">
                  <v-icon dark>{{ "mdi-content-save" }}</v-icon>
              </v-btn>

              <v-btn
                v-bind="$cfg.btra_cfg.standard"   
                style="margin-right:10px"         
                title="Copiar Agenda"
                @click="copyAgenda()">
                  <v-icon dark>{{ "mdi-content-copy" }}</v-icon>
              </v-btn>
            </div>

            <div class="conflex pt-2" v-if="tab==1 && itemsApds.length">
              <template v-for="n in nApd">
                <v-select
                  v-bind="$select"
                  v-model="asigApd[n - 1]"
                  :prepend-inner-icon="n>1? 'mdi-close' : ''"
                  :key="n"
                  :label="`APD ${n}`"
                  :items="itemsApds"
                  item-value="id"
                  item-text="name"
                  return-object
                  :disabled="n==1"
                  @click:prepend-inner="nApd--">
                </v-select>
              </template>
            </div>

            <div v-show="!copiar" class="conflex pt-2">
              <div class="conflex">
                <compfecha :schema="schema.ctrls.fhdesde" :edicion=true style="flex: 0 0 50%" />
                <compfecha :schema="schema.ctrls.fhhasta" :edicion=true style="flex: 0 0 50%" />
              </div>

              <div class="conflex">
                <v-text-field
                  style="flex: 0 0 35%"
                  v-bind="$input"
                  v-model="schema.ctrls.hdesde.value"
                  :label="schema.ctrls.hdesde.label">
                </v-text-field>

                <v-text-field
                  style="flex: 0 0 35%"
                  v-bind="$input"
                  v-model="schema.ctrls.hhasta.value"
                  :label="schema.ctrls.hhasta.label">
                </v-text-field>

                <v-checkbox
                  style="flex: 0 0 30%"
                  v-bind="$checkbox"
                  v-model="schema.ctrls.conti.value"
                  :label="schema.ctrls.conti.label"
                  title="Horario Continuado">
                </v-checkbox>
              </div>

              <div class="conflex">
                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.guardia.value"
                  :label="schema.ctrls.guardia.label"
                  :items="itemsGuardia"
                  item-value="id"
                  item-text="label">
                </v-select>
              </div>

              <div class="conflex" style="justify-content:space-between;width:250px">
                <v-checkbox                  
                  v-bind="$checkbox"
                  v-model="schema.ctrls.mff.value"
                  :label="schema.ctrls.mff.label"
                  title="Mapfre Familiar">
                </v-checkbox>

                <v-checkbox                
                  v-bind="$checkbox"
                  v-model="schema.ctrls.otc.value"
                  :label="schema.ctrls.otc.label"
                  title="Otras Compañías">
                </v-checkbox>
              </div>
            </div>

          
            <div v-show="copiar" class="conflex pt-3">
              <div class="columna">
                <div class="conflex">
                  <div>Copia de Agenda</div>
                </div>
                
                <div class="conflex  pt-2">
                  <v-btn
                    v-bind="$cfg.btra_cfg.standard"            
                    title="Guardar Copia"
                    @click="saveCopia()">
                      <v-icon dark>{{ "mdi-content-save" }}</v-icon>
                  </v-btn>              

                  <v-select
                    v-bind="$select"
                    v-model="mounth2copy"
                    label="Mes"
                    :items="mes"
                    style="padding-left:10px"
                    item-value="id"
                    item-text="label">
                  </v-select>
                </div>
              </div>
            </div>
          </v-sheet>
          <!-- fin de coluna 3 -->
        </div>
      </div>
      <!-- fin flex de columnas -->
    </div>
  </div>
</template>



<script>
  import { mixinCtrls } from "@/mixins/mixinCtrls";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");

  export default {    
    mixins: [mixinCtrls],
    components: { base_Header, compfecha },
    props: {
      Entorno: { type: Object, default: function () { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },


    created() {
      this.ini_data();
    },


    data() {
      return {
        mes: [
          { id:"0", label:"TODOS" },
          { id:"1", label:"ENE" },
          { id:"2", label:"FEB" },
          { id:"3", label:"MAR" },
          { id:"4", label:"ABR" },
          { id:"5", label:"MAY" },
          { id:"6", label:"JUN" },
          { id:"7", label:"JUL" },
          { id:"8", label:"AGO" },
          { id:"9", label:"SEP" },
          { id:"10", label:"OCT" },
          { id:"11", label:"NOV" },
          { id:"12", label:"DIC" }
        ],
        a: [],
        c: [
          { background: "grey" },
          { background: "green" },
          { background: "green" },
          { background: "green" },
          { background: "blue" },
          { background: "yellow" },
          { background: "yellow" },
          { background: "yellow" },
          { background: "blue" },
          { background: "red" },
        ],

        schema: {
          ctrls: {
            zona: { f:'zona', label:'', value:'0' },
            year: { f:'year', label:'Año', value:new Date().getFullYear() },
            mounth: { f:'mounth', label:'Mes', value:'0' },
            age: { f:'age', label:'Agencias', value:'0' },
            cia: { f:'cia', label:'CIA', value:'0' },
            fhdesde: { f:'fhdesde', label:'Desde Fh', value:'',
              comp: {
                type:'fecha',
                format:'fh'
              }
            },
            fhhasta: { f:'cia', label:'Hasta Fh', value:'',
              comp: {
                type:'fecha',
                format:'fh'
              }
            },
            hdesde: { f:'hdesde', label:'Hr Desde', value:'0' },
            hhasta: { f:'hhasta', label:'Hr Hasta', value:'23' },
            conti: { f:'conti', label:'Horario', value:'0' },
            guardia: { f:'guardia', label:'Asignar', value:'0' },
            mff: { f:'mff', label:'Mapfre', value:'1' },
            otc: { f:'otc', label:'Otras CIAS', value:'0' },
          }
        },
        tab:1,
        items:[],
        itemsZonas:[],
        itemsApds: [],
        selectedApd: null,
        itemsMunicipio: [],
        selectedMunicipio: null,
        itemsYear:[
          { id:'0', label:new Date().getFullYear() - 1 },
          { id:'1', label:new Date().getFullYear() },
          { id:'2', label:new Date().getFullYear() + 1 }
        ],

        itemsAgencia:[],
        itemsCia:[],
        asigApd:[],
        nApd:1,
        itemsGuardia:[
          { id:'0', label:'Libre' },
          { id:'1', label:'Guardia' },
          { id:'2', label:'Apoyo' },
          { id:'3', label:'No Disponible' },
        ],

        mounth2copy:'0',
        copiar:false
      };
    },


    methods: {
      ini_data() {
        this.Entorno.header.titulo = "Agenda";

        // items adicionales
        // array de zonas
        this.itemsZonas= this.$store.state.datos_iniciales.zonas;
        this.itemsZonas.unshift({ id: "0", name: "Selecciona Coordinador Servicios" });
      
        this.schema.ctrls.zona.value= this.zona;  
        if (this.zona>0) this.changeJZ();

        // array de Cias
        this.itemsCia= [
          { id:'0', label:'MFF' },
          { id:'1', label:'OTC'}
        ]
      },


      horas(hora) {
        return hora.split("");
      },


      // entro al cambiar de Tab
      changeTab(tab) {
        this.tab= tab;
        this.getCuadrante();
      },


      // entro al cambiar de Jefe de Zona
      async changeJZ() {
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'agenda_apds',
          zona:this.schema.ctrls.zona.value
        }};
        console.log('args get_datos: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // guardo todos los arrays
        this.items= apiResult.r;

        // reseteo nº apds
        this.nApd= 1;

        // actualizo datos de arrays
        // array de APDS
        this.changeAgencia(apiResult.r[1][0].id);
        //this.selectedApd= this.itemsApds.length? 0 : null;

        // array de municipios
        this.itemsMunicipio= apiResult.r[2];
        this.selectedMunicipio= this.itemsMunicipio.length? 0 : null;

        // array de agencias
        this.itemsAgencia= apiResult.r[1];
        this.schema.ctrls.age.value= this.itemsAgencia[0].id;

        // obtengo los datos del cuadrante
        //this.getCuadrante();
      },


      // cambio de Apd
      // reseteo nº apds y obtengo el cuadrante de ese Apd
      change_Apd(item) {      
        this.nApd= 1;
        this.getCuadrante(item);
      },


      // al cambiar de Año obtengo el cuadrante
      changeYear() {
        this.getCuadrante();
      },


      // entro al cambiar el select de Agencias
      // filtro el listado de APDS según la agencia seleccionada
      changeAgencia(id) {
        this.itemsApds= JSON.parse(JSON.stringify(this.items[0])).filter(elem=> elem.idage== id );
        this.selectedApd= this.itemsApds.length? 0 : null;

        // obtengo los datos del cuadrante
        this.getCuadrante();
      },


      // entro al cambiar el select de Cias
      // actualizo checkbox's MFF y OTC de la asignación según cia seleccionada
      changeCia(id) {
        this.schema.ctrls.mff.value= id==0? "1" : "0";
        this.schema.ctrls.otc.value= id==1? "1" : "0";
      },


      // obtengo los datos del cuadrante
      async getCuadrante(item= null) {
        // compruebo que haya datos en el array de APDS o Municipio (según tab seleccionada)
        if (this.tab==1 && !this.itemsApds.length) { this.a= []; return; }
        if (this.tab==2 && !this.itemsMunicipio.length) { this.a= []; return; }

        // si no recibo el item de APDS o Municipios cargo el cuadrante del primer
        // elemento del array (según tab seleccionada)
        if (item== null) item= this.tab==1? this.itemsApds[this.selectedApd] : this.itemsMunicipio[this.selectedMunicipio];
        
        // actualizo combo asignación con el apd guardado
        this.asigApd[0]= item;

        // API    
        let args = { tipo: "fnc", accion: "apds", fn_args: { yy: this.schema.ctrls.year.value, cia: 0 }};
        args.fn_args['accion']= this.tab==1? "agenda" :  "agenda_cpo";
        args.fn_args['accion']= this.tab==1? "agenda" :  "agenda_cpo";
        this.tab==1? args.fn_args['apd']= item.id : args.fn_args['cpo']= item.codigo;
    
        console.log("getCuadrante args: ", args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args: args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
        console.log("getCuadrante apiResult:", apiResult);
        (this.a = apiResult.r[0]), console.log(this.a);
      },


      //
      get_title(mes, dia, hora) {
        return dia + " " + this.mes[mes].label + " " + String(hora).padStart(2, "0") + ":00";
      },


      // añadir combo Apd
      addApd() {     
        if (!this.itemsApds.length) return;
        if (this.nApd> 10) return;
        if (typeof this.asigApd[this.nApd - 1]==="undefined") return;

        this.nApd++;
      },


      // guardar asignación
      async saveAsignacion() {
        if (!this.itemsApds.length) return;

        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'agenda_asignar',
          apd:this.splitApd(),
          fhd:this.schema.ctrls.fhdesde.value,
          fhh:this.schema.ctrls.fhhasta.value,
          hod:this.schema.ctrls.hdesde.value,
          hoh:this.schema.ctrls.hhasta.value,
          hc:this.schema.ctrls.conti.value,
          std:this.schema.ctrls.guardia.value,
          mff:this.schema.ctrls.mff.value,
          otc:this.schema.ctrls.otc.value
        }};
        console.log('args get_datos: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        this.a = apiResult.r[0];
      },


      // copiar Agenda
      copyAgenda() {
        if (!this.itemsApds.length) return;
        this.copiar= !this.copiar;
      },


      // guardo la copia de Agenda
      async saveCopia() {
        if (this.asigApd.length<= 1 || !this.asigApd[1]) {
          this.$root.$alert.open('Debe seleccionar un APD', 'info');
          return;
        }

        // API
        let args = { tipo:'fnc', accion: 'apds', fn_args: { 
          accion:'agenda_copiar',
          apd:this.splitApd(),
          yy:this.schema.ctrls.year.value,
          mes:this.mounth2copy
        }};
        console.log('args get_datos: ', args);
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        console.log('apiResult get_datos: ', apiResult);        
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }
      },


      //
      splitApd() {
        return JSON.parse(JSON.stringify(this.asigApd)).map(elem=> elem.id );
      },


      // búsqueda de Apd
      buscarApd() {      
        this.$root.$common_finder.open('busqueda_Apd');
      },


      //
      event_Header() {
        this.$router.push({ path:'/' });
      },
    },
  };
</script>



<style scoped>
.casilla {
  color: white;
  font-size: 8px;
  width: 21px;
  height: 21px;
  background: rgb(22, 69, 100);
  text-align: center;
  border: 1px solid white;
}
.mes {
  flex: 0 0 50px;
  color: white;
  font-size: 9px;
  font-weight: bold;
  width: 50px;
  height: 21px;
  background: rgb(22, 69, 100);
  text-align: center;
  padding-top: 4px;
  border: 1px solid white;
}

.hora {
  color: white;
  font-size: 10px;
  font-weight: bold;
  width: 21px;
  height: 21px;
  background: rgb(22, 69, 100);
  text-align: center;
  padding-top: 4px;
  border: 1px solid white;
}
</style>